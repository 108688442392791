<template>
  <section class="nav-wrap">
    <el-menu ref="menu" :default-active="activeId"  text-color="#444444" active-text-color="#3472c7" :router="false" class="el-menu-demo" mode="horizontal">
      <el-menu-item style="padding: 0" v-for="item in navList" v-if="item.name !== '系统设置'" :key="item.id" :index="item.url" @click.native="urlChange(item.url)">
<!--        <router-link v-if="item.name !== '数据大屏'" :to="{path:item.url}">{{ item.name }}</router-link>-->
        <div style="padding: 0 20px"
             :id="item.name === '舆情概况' ? 'guideElement13' : item.name === '数据统计' ? 'guideElement21' : item.name === '情报中心' ? 'guideElement23' : item.name === '收藏夹' ? 'guideElement24' : item.name === '舆情报告 ' ? 'guideElement25' : item.name === '事件演化' ? 'guideElement27' : item.name === '预警消息' ? 'guideElement28' : ''">
          <a>{{ item.name }}</a>
        </div>
      </el-menu-item>
      <el-submenu v-else :index="item.id" :popper-append-to-body="false">
        <template slot="title">{{item.name}}</template>
        <el-menu-item v-for="i in item.children" :key="i.name" :index="i.url" style="font-size: 16px;"  @click.native="urlChange(i.url)">
          <router-link :to="{path:i.url}">
            {{i.name}}
          </router-link>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <!-- <ul>
      <li v-for="item in navList" :key="item.id">
        <el-dropdown trigger="click" placement="bottom-start" v-if="item.name === '系统设置'">
          <span class="el-dropdown-link" style="color: #444444;font-size: 18px;">
            {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link v-for="i in item.children" :key="i.name" :to="{path:i.url}"  @click.native="urlChange(i.url)">
              <el-dropdown-item >{{i.name}}</el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link :to="{path:item.url}" v-else @click.native="urlChange(item.url)">{{ item.name }}</router-link>
      </li>
    </ul> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
// import { testApi } from '@/api/settings/tag'
export default {
  data () {
    return ({
      activeId: ''
    })
  },
  methods: {
    urlChange (url) {
      if (url === '/dataView') {
        let newpage = this.$router.resolve({
          path: url
        })
        window.open(newpage.href, '_blank')
      } else {
        this.$router.push(url)
      }
      this.$store.dispatch('app/setUrl', url)
    },
    goToDataView (item) {
      // let newpage = this.$router.resolve({
      //   path: '/dataView'
      // })
      // window.open(newpage.href, '_blank')
    }
    // async test () {
    //   try {
    //     const res = await testApi({ text: 'GOD' })
    //     console.log(res)
    //     this.woshi = res.data
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
  },
  mounted () {
    // this.test()
    this.$nextTick(()=>{
      this.navList.forEach((item,index)=>{
        if(this.$route.path == item.url){
          this.activeId = item.url
        }else if(this.$route.path.indexOf('monitor')!== -1 && item.url=='/monitor/list'){
          this.activeId = item.url
        }else{
          item.children.forEach((v,i)=>{
            if(this.$route.path == v.url){
              this.activeId = v.url
            }
          })
        }
      })
    })
  },
  computed: mapGetters(['navList']),
  watch: {
    '$route': {
      handler: function (to, from) {
        // 根据当前的路由路径设置激活项
        console.log(to.path)
        if (to.path.indexOf('/monitor/list/') !== -1) {
          this.activeId = '/monitor/list'
        } else if (to.path === '/perceptionReport/templates') {
          this.activeId = '/perceptionReport/dailyReport'
        } else {
          this.activeId = to.path
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-wrap {
  width: 100%;
  height: 71px;
  background: #fff;
  color: #444444;
  .el-menu{
    width: 100%;
    height: 100%;
    padding: 10px 40px;
    padding-left: 10px;
    /deep/ .el-menu-item,/deep/ .el-submenu__title{
      font-size: 17px;
    }
    .el-menu-item:hover a{
      color: #3472c7;
    }
    /deep/.el-menu--popup{
      min-width: fit-content;
    }
    .el-submenu /deep/.el-menu-item{
      min-width: fit-content;
    }
  }
}
</style>

import Push from 'push.js'
import Router from '@/router'
import { Notification } from 'element-ui';

let num = 0;
export function push (msg,node) {
  return Push.Permission.request(function(){  // 允许授权函数
			Push.create(msg.title ? msg.title : '', {
			    body: msg.content,
          // icon:'@/assets/images/weibo.png',
			    timeout: 5000,
			    onClick: function () {
            this.close();
            const { href } = Router.resolve({
              name: 'NewdetailBySn',
              params: {
                sn: msg.sn
              }
            })
            window.open(href, '_blank')
			    }
			});
		},function(){  // 禁止授权函数
      num ++
      if(num > 3) {
        msg = {}
        return ;
      }
      Notification({
          title: msg.title ? msg.title : '',
          message: node('p', { style: 'text-overflow: -o-ellipsis-lastline; overflow: hidden; text-overflow: ellipsis;display: -webkit-box; -webkit-line-clamp: 3; line-clamp: 3;-webkit-box-orient: vertical;'},
    msg.content),
          position: 'bottom-right',
          duration: 5000,
          customClass: "stylecss",
          onClick: function () {
              // Router.push('/warningMessage')
              this.close();
              const { href } = Router.resolve({
                name: 'NewdetailBySn',
                params: {
                  sn: msg.sn
                }
              })
              window.open(href, '_blank')
          },
          onClose: function(){
            num--
          }
        });
		});
}

<template>
  <section class="bottom-wrap" style="display: grid; grid-template-columns: 720px auto 260px;">
    <div class="msg">
      <div class="contact" style="display: grid; grid-template-columns: auto auto;">
        <span class="phone" style="display: grid; grid-template-columns: 60px auto; align-items: center">
          <img style="width: 50px; height: 50px" src="@/assets/images/tel.png" />
          <div>
            <div style="font-size: 22px">
              400-618-7710
            </div>
            <div style="font-size: 14px; color: rgba(255, 255, 255, 0.6); margin-top: 5px">
              24小时服务热线
            </div>
          </div>
        </span>
        <span class="phone" style="display: grid; grid-template-columns: 60px auto; align-items: center">
          <img style="width: 50px; height: 50px" src="@/assets/images/email.png" />
          <div>
            <div style="font-size: 22px">
              admin@congting.com
            </div>
            <div style="font-size: 14px; color: rgba(255, 255, 255, 0.6); margin-top: 5px">
              产品建议、项目合作，有邮件必复
            </div>
          </div>
        </span>
      </div>
      <div class="tips">
        <div style="font-size: 14px">
          Copyright @ 2015-2024 congting.com All rights reserved&nbsp;&nbsp;&nbsp;&nbsp;京ICP备16024387号-13 京公网安备 11011402012378号
        </div>
      </div>
    </div>
    <div></div>
    <div class="code" style="display: grid; grid-template-columns: auto auto; column-gap: 20px">
      <div>
        <div class="qr-code">
          <img src="@/assets/images/j2.png" >
        </div>
        <div class="qr-title">产品经理微信</div>
      </div>
      <div>
        <div class="qr-code">
          <img src="@/assets/images/gzh.png" >
        </div>
        <div class="qr-title">公众号</div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style lang="scss">
  .bottom-wrap{
    width: 100%;
    height: 190px;
    background: #3A404E;
    padding: 35px 100px 30px;
    color: #fff;
  }

  .msg{
    display: inline-block;
    vertical-align: top;
    .contact{
      margin-bottom: 50px;
      span{
        margin-right: 30px;
      }
    }
    .tips{
      line-height: 1.8;
    }
  }
  .code{
    margin-top: -15px;
    display: inline-block;
    float: right;
    .qr-code{
      width: 100px;
      height: 100px;
      padding: 10px;
      background-color: #fff;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .qr-title{
      width: 100px;
      text-align: center;
      margin-top: 15px;
      margin-left: 15px;
      font-size: 14px;
    }
  }
</style>

<template>
  <div id="app" :style="this.firstGuide !== -1 ? {overflow: 'hidden'} : {}">
    <div v-if="$route.path != '/login' && $route.path.indexOf('/wisdomLib/') === -1 && this.firstGuide !== -1" style="width: 100vw; height: 100vh; position: fixed; left: 0; top: 0; background-color: rgba(0,0,0,.3); z-index: 9999999">
      <div v-if="this.firstGuide === 0" class="bubble2" style="width: 160px; height: 60px; background-color: #FFFFFF; position: fixed; top: 60px; right: 10px; padding-top: 10px; text-align: center; font-size: 14px">
        点击对账号进行操作
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="nextGuide">
          1/6 下一步
        </div>
      </div>
      <div v-if="this.firstGuide === 1" class="bubble" style="width: 150px; height: 60px; background-color: #FFFFFF; position: fixed; top: 60px; right: 115px; padding-top: 10px; text-align: center; font-size: 14px">
        点击进行项目切换
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="nextGuide">
          2/6 下一步
        </div>
      </div>
      <div v-if="this.firstGuide === 2" class="bubble" style="width: 150px; height: 60px; background-color: #FFFFFF; position: fixed; top: 60px; right: 170px; padding-top: 10px; text-align: center; font-size: 14px">
        点击进入下载中心
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="nextGuide">
          3/6 下一步
        </div>
      </div>
      <div v-if="this.firstGuide === 3" class="bubble" style="width: 150px; height: 60px; background-color: #FFFFFF; position: fixed; top: 60px; right: 200px; padding-top: 10px; text-align: center; font-size: 14px">
        点击进入通知中心
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="nextGuide">
          4/6 下一步
        </div>
      </div>
      <div v-if="this.firstGuide === 4" class="bubble" style="width: 150px; height: 60px; background-color: #FFFFFF; position: fixed; top: 130px; left: 143px; padding-top: 10px; text-align: center; font-size: 14px">
        点击进入数据列表
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="nextGuide">
          5/6 下一步
        </div>
      </div>
      <div v-if="this.firstGuide === 5" class="bubble" style="width: 170px; height: 60px; background-color: #FFFFFF; position: fixed; top: 130px; left: 755px; padding-top: 10px; text-align: center; font-size: 14px">
        进入智库查看精品报告
        <div style="color: #2D64B3; font-size: 14px; margin-top: 10px; text-align: right; margin-right: 15px; cursor: pointer" @click="closeGuide">
          6/6 关闭指引
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      firstGuide: -1
    }
  },
  mounted () {
    // if (localStorage.getItem('firstGuide')) {
    //   this.firstGuide = localStorage.getItem('firstGuide')
    //   if (parseInt(this.firstGuide) !== -1) {
    //     this.firstGuide = 0
    //   } else {
    //     this.firstGuide = -1
    //   }
    // } else {
    //   this.firstGuide = 0
    //   localStorage.setItem('firstGuide', 0)
    // }
  },
  methods: {
    nextGuide () {
      this.firstGuide += 1
      localStorage.setItem('firstGuide', this.firstGuide)
    },
    closeGuide () {
      this.firstGuide = -1
      localStorage.setItem('firstGuide', this.firstGuide)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, "sans-serif";
}
</style>
<style>
/*.el-checkbox__input.is-focus .el-checkbox__inner {*/
/*  background-color: #3372C7 !important;*/
/*}*/
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #3372C7 !important;
  border-color: #3372C7 !important;
}
.show-data .el-loading-spinner {
  top: 10% !important;
}
.el-tree-node:hover {
  background-color: rgb(0, 0, 0, 0.0) !important;
}
/*body {*/
/*  padding-right: 0 !important;*/
/*}*/
.bubble {
  border-radius: 5px;
}
.bubble:after {
  content:"\00a0";
  width:5px;
  height:5px;
  display:block;
  border-style:solid;
  border-width:10px;
  border-color:transparent transparent #FFF transparent;
  position:absolute;
  z-index:-1;
  top:-20px;
  left:50px;
}
.bubble2 {
  border-radius: 5px;
}
.bubble2:after {
  content:"\00a0";
  width:5px;
  height:5px;
  display:block;
  border-style:solid;
  border-width:10px;
  border-color:transparent transparent #FFF transparent;
  position:absolute;
  z-index:-1;
  top:-20px;
  left:80px;
}
</style>

const TokenKey = 'yq_token'

export function getToken () {
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  console.log(token)
  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return localStorage.removeItem(TokenKey)
}

export function getIsTrial () {
  return JSON.parse(localStorage.getItem('customType')).isTrial
}

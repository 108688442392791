import request from '@/utils/request'

export function login (data) {
  return request({
    url: '/login',
    method: 'POST',
    data
  })
}

export function logout () {
  return request({
    url: '/logout',
    method: 'POST'
  })
}

export function resetPassword (data) {
  return request({
    url: '/sys/user/password',
    method: 'POST',
    data
  })
}

export function getUserInfo () {
  return request({
    url: '/sys/user/info',
    method: 'GET'
  })
}
export function verificationDelay () {
  return request({
    url: '/verificationDelay',
    method: 'POST'
  })
}
export function queryBind () {
  return request({
    url: '/Warning/queryBind',
    method: 'POST'
  })
}
export function makeQrcode () {
  return request({
    url: '/Warning/makeQrcode',
    method: 'POST'
  })
}
export function unBind () {
  return request({
    url: '/Warning/unBind',
    method: 'POST'
  })
}
export function getCusName () {
  return request({
    url: '/cus/getCusName',
    method: 'POST'
  })
}
export function oToc () {
  return request({
    url: '/cus/oToc',
    method: 'POST'
  })
}
export function getRemainingDaysToExpiration () {
  return request({
    url: '/cus/getRemainingDaysToExpiration',
    method: 'GET'
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/overview'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'wisdomLib',
        component: () => import('@/views/wisdomLib'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'wisdomLib/:id/:token',
        component: () => import('@/views/wisdomLib'),
        meta: {
          requireAuth: false,
          type: 'share'
        }
      },
      {
        path: 'wisdomLib/:id/:token/:shareid',
        component: () => import('@/views/wisdomLib'),
        meta: {
          requireAuth: false,
          type: 'share'
        }
      },
      {
        path: 'wisdom-upload-admin',
        component: () => import('@/views/wisdomLib/upload-admin'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  },
  {
    path: '/settings',
    component: Layout,
    // 标识该路由是否需要登录
    meta: { name: '系统设置', requireAuth: true },
    children: [
      {
        path: 'project',
        component: () => import('@/views/settings/project'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        // meta: { name: '项目设置', parent: '系统设置' },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/project/components/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add',
            component: () => import('@/views/settings/project/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:id',
            component: () => import('@/views/settings/project/components/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'custom',
            component: () => import('@/views/settings/project/components/custom'),
            meta: {
              title: '项目管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'cproject',
            component: () => import('@/views/settings/project/cProject/list'),
            meta: {
              title: '终端项目',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'cproject/add',
            component: () => import('@/views/settings/project/cProject/detail'),
            meta: {
              title: '终端项目',
              requireAuth: true,
              type: 'add'
            }
          },
          {
            path: 'cproject/detail/:id',
            component: () => import('@/views/settings/project/cProject/detail'),
            meta: {
              title: '终端项目',
              requireAuth: true,
              type: 'edit'
            }
          },
          {
            path: 'keyWords',
            component: () => import('@/views/settings/project/keywords/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'keyWords/add',
            component: () => import('@/views/settings/project/keywords/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'keyWords/edit/:id',
            component: () => import('@/views/settings/project/keywords/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'reclassification',
            component: () => import('@/views/settings/reclassification'),
            meta: {
              title: '重新分类',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'tag',
            component: () => import('@/views/settings/tag'),
            meta: {
              title: '标签组管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'tag/:id',
            component: () => import('@/views/settings/tag/components/tags'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'report',
            name: 'setAutoReport',
            component: () => import('@/views/settings/report/setAutoReport'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      },
      {
        path: 'newWord',
        component: () => import('@/views/settings/newWord'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/newWord'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      },
      {
        path: 'userManagement',
        component: () => import('@/views/settings/userManagement'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/userManagement/components/list'),
            meta: {
              title: '用户管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'editCusUser/:id',
            component: () => import('@/views/settings/userManagement/components/list'),
            meta: { type: 'editCusUser', requireAuth: true }
          },
          {
            path: 'add/:cid',
            component: () => import('@/views/settings/userManagement/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:roleId/:id',
            component: () => import('@/views/settings/userManagement/components/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'settingPermissions/:cid/:id/:c',
            component: () => import('@/views/settings/permission/index'),
            meta: { type: 'setRole', requireAuth: true }
          }
        ]
      },
      {
        path: 'permission',
        name: 'permission',
        component: () => import('@/views/settings/permission'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'theme',
        component: () => import('@/views/settings/theme'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: 'show/:id',
            component: () => import('@/views/settings/theme/components/monitor'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: '',
            component: () => import('@/views/settings/theme/components/monitor'),
            meta: {
              title: '监测主题管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add/:cid',
            component: () => import('@/views/settings/theme/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:id/:cid',
            component: () => import('@/views/settings/theme/components/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'classification/:id',
            component: () => import('@/views/settings/theme/components/classification'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'addclass/:id/:typeName/:subjectId/:cid',
            component: () => import('@/views/settings/theme/components/classForm'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'editclass/:id/:cid/:pid',
            component: () => import('@/views/settings/theme/components/classForm'),
            meta: { type: 'edit', requireAuth: true }
          }
          /* {
            path: 'reclassification',
            component: () => import('@/views/settings/reclassification')
          },
          {
            path: 'tag',
            component: () => import('@/views/settings/tag')
          } */
        ]
      },
      {
        path: 'warning',
        component: () => import('@/views/settings/warning'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/warning/components/list'),
            meta: {
              title: '预警管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add',
            component: () => import('@/views/settings/warning/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:id',
            component: () => import('@/views/settings/warning/components/form'),
            meta: { type: 'edit', requireAuth: true }
          }
        ]
      },
      {
        path: 'push',
        component: () => import('@/views/settings/push'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/push/components/list'),
            meta: {
              title: '系统消息推送',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add',
            component: () => import('@/views/settings/push/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:id',
            component: () => import('@/views/settings/push/components/form'),
            meta: { type: 'edit', requireAuth: true }
          }
        ]
      },
      {
        path: 'emotion',
        component: () => import('@/views/settings/emotion'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/emotion/components/parameter'),
            meta: {
              title: '情感分数设置',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'negativeThesaurus',
            component: () => import('@/views/settings/emotion/components/negativeThesaurus'),
            meta: {
              title: '负面词库',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'emotionalWords',
            component: () => import('@/views/settings/emotion/components/emotionalWords'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'affectiveWordList',
            component: () => import('@/views/settings/emotion/components/affectiveWordList'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      },
      {
        path: 'role',
        component: () => import('@/views/settings/role'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/role/components/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'usersMsg',
            component: () => import('@/views/settings/role/users/form'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'users',
            component: () => import('@/views/settings/role/users/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'cusAccount',
            component: () => import('@/views/settings/role/cusAccount/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'cusAccountMsg',
            component: () => import('@/views/settings/role/cusAccount/form'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      },
      {
        path: 'apply',
        component: () => import('@/views/settings/applyList'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'notice',
        component: () => import('@/views/settings/notification'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'clog',
        component: () => import('@/views/settings/log/clog'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  },
  {
    path: '/siteMedia',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/settings/siteMedia'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/settings/siteMedia/components/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/spider',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/settings/spider'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: []
      },
      {
        path: 'add',
        component: () => import('@/views/settings/spider/form'),
        meta: {
          requireAuth: true,
          type: 'add'
        }
      },
      {
        path: 'edit/:id',
        component: () => import('@/views/settings/spider/form'),
        meta: {
          requireAuth: true,
          type: 'edit'
        }
      }
    ]
  },
  {
    path: '/statistics',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/statistics'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: 'total',
            component: () => import('@/views/statistics/components/total'),
            meta: {
              title: '总量统计',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'dimension',
            component: () => import('@/views/statistics/components/dimension'),
            meta: {
              title: '数据统计',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'export',
            name: 'export',
            component: () => import('@/views/statistics/components/export'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/warningMessage',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/warningMessage/'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/warningMessage/components/list'),
            meta: {
              title: '预警消息',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'send',
            component: () => import('@/views/warningMessage/components/send'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'newsDetail/:sn',
            name: 'warnNewsDetail',
            component: () => import('@/views/warningMessage/components/newsDetail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/feedback',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/feedback/'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/feedback/components/correction'),
            meta: {
              title: '反馈管理',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'problem',
            component: () => import('@/views/feedback/components/problem'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/tryOut', // 页面完整
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/tryOut/'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/tryOut/components/list'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add',
            component: () => import('@/views/tryOut/components/add'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'edit/:id ',
            component: () => import('@/views/tryOut/components/edit'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/intelligence',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/intelligence/'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/intelligence/components/send'),
            meta: {
              title: '情报中心',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'revoke',
            component: () => import('@/views/intelligence/components/revoke'),
            meta: {
              title: '情报中心',
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/eventEvolution',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/eventEvolution/'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/eventEvolution/components/list'),
            meta: {
              title: '事件演化',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'modifyEvent/:id',
            component: () => import('@/views/eventEvolution/components/modifyEvent'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'option/:id/:name',
            component: () => import('@/views/eventEvolution/components/option'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'addEvent',
            component: () => import('@/views/eventEvolution/components/addEvent'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'evolutionDetails/:id',
            component: () => import('@/views/eventEvolution/components/evolutionDetails'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'deleted',
            component: () => import('@/views/eventEvolution/components/deleted'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'auditList',
            component: () => import('@/views/eventEvolution/components/auditList'),
            meta: {
              title: '事件演化',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'eventEdit/:id',
            component: () => import('@/views/eventEvolution/components/eventEdit'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'newsDetail/:sn',
            name: 'newsDetail',
            component: () => import('@/views/eventEvolution/components/newsDetail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/monitor',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/detection/index'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: 'list',
            component: () => import('@/views/detection/components/tabs'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'list/:id/:nodeId',
            component: () => import('@/views/detection/components/tabs'),
            meta: {
              title: '舆情监测',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'add/:id/:nodeId',
            component: () => import('@/views/detection/components/form'),
            meta: { type: 'add', requireAuth: true }
          },
          {
            path: 'edit/:sn/:id/:nodeId/:pubDate/:date1/:date2',
            component: () => import('@/views/detection/components/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'edit/:sn/:id/:nodeId/:editType/:pubDate/:date1/:date2',
            component: () => import('@/views/detection/components/form'),
            meta: { type: 'edit', requireAuth: true }
          },
          {
            path: 'detail/:sn/:id/:nodeId/:pubDate/:date1/:date2',
            name: 'details',
            component: () => import('@/views/detection/components/detail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'detail/:sn/:id/:nodeId/:pubDate/:date1/:date2/:keyword',
            name: 'detailsKeyword',
            component: () => import('@/views/detection/components/detail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'detail/:sn',
            name: 'NewdetailBySn',
            component: () => import('@/views/detection/components/detail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'detail/:sn/:keyword/:detailkeyword',
            name: 'NewdetailBySnKeyword',
            component: () => import('@/views/detection/components/detail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'detail/:sn/:type',
            name: 'newsdetail',
            component: () => import('@/views/detection/components/detail'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/perceptionReport',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/perceptionReport'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        },
        children: [
          {
            path: '',
            component: () => import('@/views/perceptionReport/components/dataOutput'),
            meta: {
              title: '收藏夹',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'dailyReport',
            component: () => import('@/views/perceptionReport/components/dailyReport'),
            meta: {
              title: '舆情报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReport',
            component: () => import('@/views/perceptionReport/components/diyReport'),
            meta: {
              type: 'add',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReportForC',
            component: () => import('@/views/perceptionReport/components/diyReportForC'),
            meta: {
              type: 'add',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReportAdmin',
            component: () => import('@/views/perceptionReport/components/diyReportAdmin'),
            meta: {
              type: 'add',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReport/edit/:id',
            component: () => import('@/views/perceptionReport/components/diyReport'),
            meta: {
              type: 'edit',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReportForC/edit/:id',
            component: () => import('@/views/perceptionReport/components/diyReportForC'),
            meta: {
              type: 'edit',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReportAdmin/edit/:id',
            component: () => import('@/views/perceptionReport/components/diyReportAdmin'),
            meta: {
              type: 'edit',
              title: '自定义报告',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'diyReport/import/:id',
            component: () => import('@/views/perceptionReport/components/diyReport'),
            meta: {
              type: 'import',
              title: '快速导入模板',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'templates',
            component: () => import('@/views/perceptionReport/components/templates'),
            meta: {
              title: '自定义报告模板',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'templatesForC',
            component: () => import('@/views/perceptionReport/components/templatesForC'),
            meta: {
              title: '自定义报告模板',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          {
            path: 'templatesAdmin',
            component: () => import('@/views/perceptionReport/components/templatesAdmin'),
            meta: {
              title: '系统内置报告模板',
              requireAuth: true // 标识该路由是否需要登录
            }
          },
          /* {
            path: 'dailyTemplate',
            component: () => import('@/views/perceptionReport/components/dailyTemplate')
          },
          {
            path: 'dailyData',
            component: () => import('@/views/perceptionReport/components/dailyData')
          }, */
          {
            path: 'dailyPreview',
            name: 'dailyPreview',
            component: () => import('@/views/perceptionReport/components/dailyPreview'),
            meta: {
              requireAuth: true // 标识该路由是否需要登录
            }
          }
        ]
      }
    ]
  },
  {
    path: '/dataTrack',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/dataTrack/components/list'),
        meta: {
          title: '数据追踪',
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'dataDetails/:sn',
        name: 'dataDetail',
        component: () => import('@/views/dataTrack/components/dataDetail'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      },
      {
        path: 'applyList',
        component: () => import('@/views/dataTrack/components/applyList'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  },
  {
    path: '/apply',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: '',
        component: () => import('@/views/apply'),
        meta: {
          title: '公众号试用列表',
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  },
  {
    path: '/login2',
    component: () => import('@/views/login')
  },
  {
    path: '/login',
    component: () => import('@/views/login/login')
  },
  {
    path: '/dataView',
    component: () => import('@/views/dataView'),
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    }
  },
  {
    path: '',
    component: Layout,
    meta: {
      requireAuth: true // 标识该路由是否需要登录
    },
    children: [
      {
        path: 'noPermission',
        component: () => import('@/views/settings/common/noPermission'),
        meta: {
          requireAuth: true // 标识该路由是否需要登录
        }
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router

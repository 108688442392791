<template>
  <section class="bottom-wrap">
    <div class="msg">
      <div class="contact">
        <span class="phone">联系电话：021 - 6335 1152；150 2199 3811</span>
        <span class="phone">邮箱：joyce.yang@tiansongdata.com‍</span>
      </div>
      <div class="tips">
        <div>
          ©2020 天颂（上海）信息科技有限公司版权所有
        </div>
        <div>
         沪公网安备 31010102006576号 沪ICP备17030436号-1
        </div>
      </div>
    </div>
    <div class="code">
      <div class="qr-code">
        <img src="../../assets/images/ts_qrcode.png" >
      </div>
      <!-- <div class="qr-title">公众号</div> -->
    </div>
  </section>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style lang="scss">
  .bottom-wrap{
    width: 100%;
    height: 190px;
    background: #3A404E;
    padding: 35px 100px 30px;
    color: #fff;
  }

  .msg{
    display: inline-block;
    vertical-align: top;
    .contact{
      margin-bottom: 50px;
      span{
        margin-right: 30px;
      }
    }
    .tips{
      line-height: 1.8;
    }
  }
  .code{
    display: inline-block;
    float: right;
    .qr-code{
      width: 130px;
      height: 130px;
      padding: 5px;
      background-color: #fff;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .qr-title{
      width: 100px;
      text-align: center;
      margin-top: 15px;
    }
  }
</style>

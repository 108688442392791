import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// style
import 'normalize.css'
import '@/styles/index.scss'
import '@/styles/iconfont.css'
import 'font-awesome/css/font-awesome.min.css'
// echarts 图表
import * as echarts from 'echarts'
import visibility from 'vue-visibility-change';
import vuetify from '@/plugins/vuetify'
import { getIsTrial } from '@/utils/auth'
Vue.prototype.$echarts = echarts
require('echarts-wordcloud')
Vue.use(visibility)
Vue.config.productionTip = false
Vue.prototype.$showGuide = function (title, summary, elementId, insertType, xd, backgroundColor, borderRadius, delay) {
  if (!getIsTrial || window.location.hostname === 'monitor.tiansongdata.com') {
    return
  }
  var arrowBoxTipElement = document.querySelector('.arrow-box-tip');

  if (arrowBoxTipElement) {
    return
  }
  const element = document.getElementById(elementId)
  if (!element) {
    console.error(`Element with ID ${elementId} not found.`)
    return
  }
  document.body.style.overflowY = 'hidden'
  const positionValue = window.getComputedStyle(element).getPropertyValue('position')
  const zIndexValue = window.getComputedStyle(element).getPropertyValue('zIndex')
  const bc = window.getComputedStyle(element).getPropertyValue('backgroundColor')
  const br = window.getComputedStyle(element).getPropertyValue('borderRadius')
  element.style.zIndex = '2002'
  element.style.position = 'relative'
  if (backgroundColor) {
    element.style.backgroundColor = backgroundColor
  }
  if (borderRadius) {
    element.style.borderRadius = borderRadius
  }
  // element.style.borderRadius = '10px'
  const rect = element.getBoundingClientRect()

  const guideElement = document.createElement('div')
  guideElement.className = 'guide-overlay'
  guideElement.style.position = 'fixed'
  guideElement.style.top = '0'
  guideElement.style.left = '0'
  guideElement.style.width = '100%'
  guideElement.style.height = '100%'
  guideElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
  guideElement.style.zIndex = '2001'

  let currentGuideNumV2 = parseInt(localStorage.getItem('currentGuideNumV2')) || 1
  const arrowBox = document.createElement('div')
  arrowBox.className = 'arrow-box-tip'
  arrowBox.innerHTML = `
    <div style="font-weight: bold; margin-bottom: 10px; padding-left: 10px; text-align: left">` + title + '<span style="float: right; margin-right: 10px">' + (currentGuideNumV2 <= 13 ? currentGuideNumV2 : (currentGuideNumV2 - 7))  + '&nbsp;/&nbsp;21</span>' + `</div>
    <div style="background: #FFFFFF; padding: 10px; border-radius: 15px; color: #333">
      <div style="margin-top: 10px; font-size: 15px; line-height: 22px; text-align: left; white-space: normal">
        ` + summary + `
      </div>
      <div style="margin-top: 20px; display: flex; justify-content: flex-end; column-gap: 10px">
        <div id="kfys" style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: rgba(52, 114, 199, 0.9); margin-right: auto;">客服演示</div>
        <div id="nextGuideStepButton" style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: rgba(52, 114, 199, 0.9); margin-left: 10px; display: ` + (currentGuideNumV2 === 28 ? 'none' : 'flex') + `;align-items:center">
        下一步
        <svg t="1715241099733" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3108" width="16" height="16"><path d="M385.536 102.4l398.848 364.544c12.288 10.752 19.456 26.624 19.456 43.008s-7.168 32.256-19.456 43.008l-398.848 364.544c-18.944 17.92-46.08 23.552-70.656 14.336s-40.96-31.232-43.52-57.344V145.408c2.048-26.112 18.944-48.128 43.52-57.344 24.064-9.216 51.712-3.584 70.656 14.336z" fill="#3472c7" p-id="3109"></path></svg>
        </div>
        <div id="doneBtn" style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: green; margin-left: 10px; display: ` + (currentGuideNumV2 < 28 ? 'none' : 'flex') + `;align-items:center">
        完成
        </div>
      </div>
    </div>
  `
  // 获取按钮元素
  const nextGuideStepButton = arrowBox.querySelector('#nextGuideStepButton')
  const doneBtn = arrowBox.querySelector('#doneBtn')
  const kfysBtn = arrowBox.querySelector('#kfys')

  kfysBtn.addEventListener('click', () => {
    openKF()
  })

  nextGuideStepButton.addEventListener('click', () => {
    guideElement.remove()
    arrowBox.remove()
    element.style.zIndex = zIndexValue
    element.style.position = positionValue
    if (backgroundColor) {
      element.style.backgroundColor = bc
    }
    if (borderRadius) {
      element.style.borderRadius = br
    }
    let currentGuideNumV2 = parseInt(localStorage.getItem('currentGuideNumV2')) || 1
    if (currentGuideNumV2 === 13) {
      localStorage.setItem('currentGuideNumV2', currentGuideNumV2 + 8)
    } else {
      localStorage.setItem('currentGuideNumV2', currentGuideNumV2 + 1)
    }
    currentGuideNumV2 = parseInt(localStorage.getItem('currentGuideNumV2')) || 1
    switch (currentGuideNumV2) {
      case 1:
        this.$showGuide('添加监测主题', '点击添加按钮可以快速添加监测主题，每个账号有5次添加的机会', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
        break
      case 2:
        this.$showGuide('基础筛选器', '可通过发布时间、情感筛选数据，并且可以合并相似新闻，调整排序方式', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
        break
      case 3:
        this.$showGuide('来源筛选', '可通过数据来源精准筛选数据，且可查看各个来源对应数据总量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
        break
      case 4:
        this.$showGuide('视频网站筛选', '可以更加精准的对视频数据按照所属网站或客户端进行筛选，且可查看各个视频网站对应的数据总量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
        break
      case 5:
        this.$showGuide('文本检索', '支持通过各种关键词组合模糊检索数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
        break
      case 6:
        this.$showGuide('数据导出', '可以将当前查询条件的所有数据进行导出', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
        break
      case 7:
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$showGuide('报告快速导出', '可以通过提前制作好的报告模板，将当前查询条件范围内的数据进行报告生成', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
        break
      case 8:
        window.scrollTo({ top: 500, behavior: 'smooth' })
        this.$showGuide('数据导入', '支持手动对数据进行导入补充，以应对小众站点未被爬虫收录抓取对情况', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
        break
      case 9:
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$showGuide('项目切换', '点击对您对账号所管理对项目进行快速切换', 'guideElement' + currentGuideNumV2, 'after', true, '', '', 500)
        break
      case 10:
        this.$showGuide('下载中心', '点击进入下载中心查看导出任务', 'guideElement' + currentGuideNumV2, 'after', true, '', '')
        break
      case 11:
        this.$showGuide('消息中心', '点击进入消息中心查看系统消息推送', 'guideElement' + currentGuideNumV2, 'after', true, '', '')
        break
      case 12:
        this.$showGuide('跳转客户端', '点击直接免登录跳转打开客户端', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
        break
      case 13:
        router.push('/')
        this.$showGuide('舆情概况', '查看当前项目的整体概览情况，快速知晓当前网络声量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 14:
        this.$showGuide('最新舆情', '展示当前项目最新的舆情数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 15:
        this.$showGuide('负面舆情', '展示当前项目最新的负面舆情数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 16:
        window.scrollTo({ top: 500, behavior: 'smooth' })
        this.$showGuide('情感走势', '展示当前项目所有互联网声量的情感走势', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 17:
        window.scrollTo({ top: 1000, behavior: 'smooth' })
        this.$showGuide('舆情分布', '展示当前项目所有互联网声量的情感分布占比', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 18:
        window.scrollTo({ top: 1000, behavior: 'smooth' })
        this.$showGuide('站点排行', '展示当前项目所有互联网声量的站点排行TOP10', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 19:
        window.scrollTo({ top: 1500, behavior: 'smooth' })
        this.$showGuide('热词云图', '展示当前项目数据分词后统计的热词', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 20:
        window.scrollTo({ top: 1500, behavior: 'smooth' })
        this.$showGuide('标签云图', '展示当前项目命中标签的情况', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 21:
        router.push('/statistics/dimension')
        setTimeout(() => {
          router.push('/statistics/dimension')
        }, 50)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$showGuide('数据统计', '查看当前项目整体的各个纬度的统计信息', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 22:
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$showGuide('统计筛选', '根据时间进行筛选', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 23:
        router.push('/intelligence')
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$showGuide('情报中心', '查看当前项目的人工标记或系统自动标记的重点情报数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 24:
        router.push('/perceptionReport')
        setTimeout(() => {
          router.push('/perceptionReport')
        }, 50)
        window.scrollTo({ top: 0 })
        this.$showGuide('收藏夹', '对添加到收藏夹中，需要二次处理的数据进行处理', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
      case 25:
        router.push('/perceptionReport/templates')
        setTimeout(() => {
          router.push('/perceptionReport/templates')
          window.scrollTo({ top: 0 })
          this.$showGuide('舆情报告', '制作报告模板，手动生成报告，或者设置生成报告的定时任务', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        }, 50)
        break
      case 26:
        router.push('/perceptionReport/dailyReport')
        setTimeout(() => {
          router.push('/perceptionReport/diyReport/import/531051109423386624').then(() => {
            setTimeout(() => {
              document.getElementById('guideElement26-box').scrollIntoView({ block: 'start' })
              this.$showGuide('报告设计器', '拖动左侧组件库中的组件到报告模板设计器中制作报告模板', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            }, 1000)
          })
        }, 500)
        break
      case 27:
        this.$router.push('/eventEvolution').then(() => {
          window.scrollTo({ top: 0 })
          this.$showGuide('事件演化', '针对重点关注事件创建事件演化，持续关注事件走向', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        })
        break
      case 28:
        router.push('/warningMessage')
        setTimeout(() => {
          router.push('/warningMessage')
        }, 50)
        window.scrollTo({ top: 0 })
        this.$showGuide('预警消息', '查看当前项目生成的预警消息', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
        break
    }
  })

  arrowBox.style.position = 'absolute'
  arrowBox.style.width = '320px'
  arrowBox.style.padding = '20px 10px 10px 10px'
  arrowBox.style.color = '#FFF'
  arrowBox.style.backgroundColor = 'rgba(52, 114, 199, 1)'
  arrowBox.style.borderRadius = '15px'
  arrowBox.style.zIndex = '2002'
  arrowBox.style.borderColor = 'transparent transparent #FFF transparent'
  arrowBox.style.overflow = 'visible'
  arrowBox.style.marginTop = '15px'
  arrowBox.style.textAlign = 'left'
  arrowBox.style.lineHeight = '20px'
  if (currentGuideNumV2 === 26) {
    arrowBox.style.marginLeft = '400px'
    arrowBox.style.top = '250px'
  }

  // arrowBox.style.left = element.offsetWidth + 'px'

  const arrow = document.createElement('div')
  arrow.style.position = 'absolute'
  arrow.style.width = '0'
  arrow.style.height = '0'
  arrow.style.borderStyle = 'solid'
  arrow.style.borderWidth = '10px'
  arrow.style.borderColor = 'transparent transparent rgb(52, 114, 199) transparent'
  arrow.style.top = '-20px'
  arrow.style.left = '20px'
  arrow.style.transform = 'rotate(0deg)'

  if (xd) {
    arrowBox.style.top = rect.bottom - 20 + 'px'
    arrowBox.style.position = 'fixed'
    arrowBox.style.left = rect.x - 150 + 'px'
    arrow.style.top = rect.bottom - 25 + 'px'
    arrow.style.position = 'fixed'
    arrow.style.left = ((rect.right - rect.x) / 4) + rect.x + 'px'
  }

  arrowBox.appendChild(arrow)
  if (delay) {
    if (insertType === 'append') {
      element.append(guideElement)
    } else {
      element.after(guideElement)
    }
    setTimeout(() => {
      const rect2 = element.getBoundingClientRect()
      if (xd) {
        arrowBox.style.top = rect2.bottom - 20 + 'px'
        arrowBox.style.position = 'fixed'
        arrowBox.style.left = rect2.x - 150 + 'px'
        arrow.style.top = rect2.bottom - 25 + 'px'
        arrow.style.position = 'fixed'
        arrow.style.left = ((rect2.right - rect2.x) / 4) + rect2.x + 'px'
      }
      if (insertType === 'append') {
        element.append(arrowBox)
      } else {
        element.after(arrowBox)
      }
    }, delay)
  } else {
    if (insertType === 'append') {
      element.append(guideElement)
      element.append(arrowBox)
    } else {
      element.after(guideElement)
      element.after(arrowBox)
    }
  }
  doneBtn.addEventListener('click', () => {
    let currentGuideNumV2 = parseInt(localStorage.getItem('currentGuideNumV2')) || 1
    localStorage.setItem('currentGuideNumV2', currentGuideNumV2 + 1)
    guideElement.remove()
    arrowBox.remove()
    element.style.zIndex = zIndexValue
    element.style.position = positionValue
    element.style.backgroundColor = bc
    element.style.borderRadius = br
    document.body.style.overflowY = 'auto'
  })

  // 这里可以添加点击事件监听器，当点击遮罩时关闭引导
  // guideElement.addEventListener('click', () => {
  //   guideElement.remove()
  //   arrowBox.remove()
  //   element.style.zIndex = zIndexValue
  //   element.style.position = positionValue
  //   element.style.backgroundColor = bc
  //   element.style.borderRadius = br
  //   document.body.style.overflowY = 'auto'
  //   // element.style.borderRadius = '0px'
  // })
}
var sheets = document.styleSheets
for (var thisSheet = 0; thisSheet < sheets.length; thisSheet++) {
  let removeText = '[role="button"], [type="button"], [type="reset"], [type="submit"], button'
  let sheet = sheets[thisSheet]
  try {
    if (sheet.cssRules) { // all browsers, except IE before version 9
      for (var i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].selectorText === removeText) {
          sheet.deleteRule(i)
        }
      }
    } else {
      for (var j = 0; j < sheet.rules.length; j++) {
        if (sheet.rules[j].selectorText === removeText) {
          sheet.removeRule(j)
        }
      }
    }
  } catch (e) {
  }
}
router.beforeEach((to, from, next) => {
  /**
   * 未登录则跳转到登录页
   * 未登录跳转到登录页,也可以通过axios的响应拦截器去实现,但是会先在当前页面渲染一下,再跳转到登录页,会有个闪动的现象
   * 这里通过路由守卫的方式,不会在当前页闪现一下,但是需要在每个路由组件添加一个是否需要登录的标识位,如本项目中的requireAuth字段
   */
  var domain = document.domain
  var baseTitle = ''
  if (domain === 'monitor.tiansongdata.com') {
    baseTitle = '天颂舆情大数据平台'
  } else {
    baseTitle = '聪听数智舆情平台'
  }
  if (to.meta.title) {
    document.title = baseTitle + ' - ' + to.meta.title
  } else {
    document.title = baseTitle
  }
  if (to.matched[to.matched.length - 1].meta.requireAuth) {
    let token = localStorage.getItem('yq_token')
    if (token) {
      next()
      document.documentElement.scrollTop = 0;
    } else {
      next({
        path: "/login"
      })
    }
  } else {
    next()
    document.documentElement.scrollTop = 0;
  }
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

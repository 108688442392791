import { login, getUserInfo } from '@/api/user'
import { getToken, setToken } from '@/utils/auth'
import { Message } from 'element-ui'

const state = {
  token: getToken(),
  name: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  }
}

const actions = {
  // user login
  async login ({ commit }, { userInfo, callback, error }) {
    try {
      const res = await login(userInfo)
      if (res.code === 0) {
        setToken(res.data.token)
        window.localStorage.setItem("customType",JSON.stringify(res.data))
        commit('SET_TOKEN', res.data.token)
        callback()
      }else {
        // callback()
        error(res)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async UserInfo ({ commit }) {
    try {
      const res = await getUserInfo()
      commit('SET_NAME', res.data.username)
      window.localStorage.setItem("login",res.data.username)
      window.sessionStorage.setItem("curr_custom",JSON.stringify(res.data))
    } catch (err) {
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

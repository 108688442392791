<template>
  <section class="leftBar">
    <div class="menu-title">
      <img :src="imgSrc" style="width: 18px;vertical-align: sub;">
      <span class="title">{{name}}</span>
    </div>
    <section class="side-bar">
      <div style="cursor: pointer;" class="submenu" v-for="i in list" :class="activeId == i.id?'actived':''" @click="goTo(i)">
        <span>{{i.name}}</span>
      </div>
      <!-- <el-menu
        :default-active="id"
         :default-openeds="['0']"
        class="el-menu-vertical-demo">
        <el-submenu index="0">
          <template slot="title">
            <span>{{ name }}</span>
          </template>
          <el-menu-item-group>
            <el-tooltip v-for="i in list" :content="i.name"  v-if="i.name.length > 8" placement="bottom" effect="light">
              <el-menu-item :key="i.id" :index="i.id" @click="goTo(i.url)">
                <span>{{ i.name }}</span>
              </el-menu-item>
            </el-tooltip>
            <el-menu-item :key="i.id" :index="i.id" @click="goTo(i.url)" v-else>
              <span>{{ i.name }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu> -->
    </section>
    <div class="block"></div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    if(this.$route.path.indexOf("/monitor/list") !== -1 || this.$route.path.indexOf("/monitor/add") !== -1){
      this.getList('/monitor/list/'+this.$route.path.substr(1).split("/")[2]+'/0')
    } else if (this.$route.path.indexOf("/perceptionReport") !== -1) {
      this.getList('/perceptionReport/dailyReport')
    } else if (this.$route.path.substr(1).split("/")[0] === 'settings'){
      if(this.$route.path.substr(1).split("/")[1]==='theme'|| this.$route.path.substr(1).split("/")[1]==='userManagement'){
        if (this.$route.path.substr(1).split("/")[2]){
          this.getList('/settings/project')
        }else{
          this.getList('/settings/project/custom')
        }
      }else if(this.$route.path.substr(1).split("/")[1]==='project') {
        if (this.$route.path.substr(1).split("/")[2]){
          this.getList('/settings/project/custom')
        }else{
          this.getList('/settings/project')
        }
      }else if (this.$route.path.substr(1).split("/")[1] === 'role') {
          this.getList('/settings/role')
      } else{
        this.getList(this.$route.path)
      }
    }else if (this.$route.path.substr(1).split("/")[0] === 'statistics'){
      this.getList('/statistics/dimension')
    }else if(this.$route.path.substr(1).split("/")[1]){
      this.getList('/'+this.$route.path.substr(1).split("/")[0])
    }else{
      this.getList(this.$route.path)
    }
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      navMapList: {},
      top: 130,
      id:'',
      imgSrc:require('@/assets/images/icons/icon-shijianyanhua.png'),
      activeId: '',
      visible: false,
      dialogFormVisible: false,
      editVisible: false,
      name: null,
      list: null,
      form:{
        name:''
      }
    }
  },
  methods: {
    getList (url) {
      this.navMapList = JSON.parse(sessionStorage.getItem('navMap'))
      for (let i in this.navMapList) {
        if (this.navMapList[i].url === url) {
          this.list = this.navMapList[i].children
          if( this.navMapList[i].pid === '0' ) {
            this.name = this.navMapList[i].name
          } else {
            this.name = this.navMapList[this.navMapList[i].pid].name
          }
          break;
        }
      }

      for(let i in this.list) {
        if (this.list[i].url === this.$route.path) {
          this.activeId = this.list[i].id
        }
      }
      if (this.$route.path.indexOf("intelligence") !== -1) {
        this.imgSrc = require('@/assets/images/icons/icon-qingbao.png')
      }else if (this.$route.path.indexOf("dailyReport") !== -1){
        this.imgSrc = require('@/assets/images/icons/icon-yuqingbaogao.png')
      }else if (this.$route.path.indexOf("eventEvolution") !== -1){
        this.imgSrc = require('@/assets/images/icons/icon-shijianyanhua.png')
      }else if (this.$route.path.indexOf("warningMessage") !== -1){
        this.imgSrc = require('@/assets/images/icons/icon-warnings.png')
      }else if (this.$route.path.indexOf("statistics") !== -1){
        this.imgSrc = require('@/assets/images/icons/icon-statistics.png')
      }else if (this.$route.path.indexOf("settings") !== -1){
        this.imgSrc = require('@/assets/images/icons/icon-settings.png')
      }


    },
    handleNodeClick (data) {
    },
    goTo (item) {
      this.activeId = item.id
      this.$router.push(item.url)
    },
    handleScroll () { // 改变元素#searchBar的top值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // var offsetTop = document.querySelector('#searchBar').offsetTop
      if (scrollTop >= 100) {
        this.top = 30
      } else if (scrollTop < 100) {
        this.top = 130 - scrollTop
      }
    },
    deleteItem (id) {
      console.log(id)
    },
    addItems () {
      this.dialogFormVisible = true
    },
    editMonitor () {
      this.$router.push('/settings/theme')
    }
  },
  computed: mapGetters(['navList', 'navMap'])
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';
/* .leftBar{
	.block {
	  width: 200px;
	  // height: $contentHeight;
	}
} */
.menu-title{
  background-color: #3472c7;
  height: 40px;
  line-height: 40px;
  color: #fff;
  padding-left: 10px;
  .title{
    margin-left: 5px;
  }
}
.side-bar {
  width: 260px;
  height: calc(100% - 40px);
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  // padding: 10px 0;
  // position: absolute;
  .submenu{
    font-size: 16px;
    color: #696969;
    height: 40px;
    line-height: 40px;
    padding: 0 32px;
  }
  .actived{
    color:#3472c7;
    background-color: #f0f7ff;
  }
}

</style>

import request from '@/utils/request'
export function showProject (data) {
  return request({
    url: '/cus/show_project_detail',
    method: 'POST',
    data
  })
}
export function updateProjcet (data) {
  return request({
    url: '/cus/edit',
    method: 'POST',
    data
  })
}
export function showAllCus (data) {
  return request({
    url: '/cus/show_all_cus',
    method: 'POST',
    data
  })
}
export function changeProjectState (data) {
  return request({
    url: '/cus/change_project_state',
    method: 'POST',
    data
  })
}
export function addCus (data) {
  return request({
    url: '/cus/save',
    method: 'POST',
    data
  })
}
export function allIndustry () {
  return request({
    url: '/industry/getIndustryList',
    method: 'POST',
    data: {
      size: 100,
      number: 1
    }
  })
}
export function del (data) {
  return request({
    url: '/cus/del',
    method: 'POST',
    data
  })
}
export function listCanSwitchCustomers () {
  return request({
    url: '/cus/listCanSwitchCustomers',
    method: 'POST'
  })
}
export function projectswitching (data) {
  return request({
    url: '/cus/projectswitching',
    method: 'POST',
    data
  })
}
export function isHaveSwitchPermission () {
  return request({
    url: '/cus/isHaveSwitchPermission',
    method: 'POST'
  })
}

<template>
  <div ref="wrapper" class="wraper" style="height: 100%;" v-visibility-change="change" @click="handle">
<!--    <div class="arrow-box-tip" style="transition: all .5s;">-->
<!--      <div style="font-weight: bold; margin-bottom: 10px; padding-left: 10px">添加监测主题</div>-->
<!--      <div style="background: #FFFFFF; padding: 10px; border-radius: 15px; color: #333">-->
<!--        <div style="margin-top: 10px; font-size: 15px; line-height: 22px; ">-->
<!--          演示环境中监测主题只可添加5个，且不可删除修改，请谨慎添加测试-->
<!--        </div>-->
<!--        <div style="margin-top: 20px; display: flex; justify-content: flex-end;">-->
<!--          <div-->
<!--            style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: rgba(52, 114, 199, 0.9); margin-right: auto"-->
<!--            >客服演示-->
<!--          </div>-->
<!--          <div-->
<!--            style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: rgba(52, 114, 199, 0.9); margin-left: 10px;"-->
<!--            >上一步-->
<!--          </div>-->
<!--          <div-->
<!--            style="font-size: 13px; font-weight: bold; cursor: pointer; margin-top: 10px; color: rgba(52, 114, 199, 0.9); margin-left: 10px;"-->
<!--            >下一步-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <section class="top-wrap">
      <header-wrap ref="header"/>
      <nav-wrap/>
      <el-alert center v-if="customType.type === '0'" :title="'您的账号还有'+customType.discountTime+'天到期!'"
                type="error"></el-alert>
    </section>
    <!-- <section class="app-main" v-if=" navPath === '/' || navPath === '' || navPath ==='/noPermission' || navPath.indexOf('/monitor/detail') !== -1 ">
      <router-view />
    </section> -->
    <section ref="app-main" class="app-main" :style="customType.type === '0'?'padding-top: 30px;':'padding-top: 10px;'">
      <side-bar v-if="ctAccount !== 'admin' && ctAccount !== 'ctadmin'" ref="sidebar" style="margin-left: 20px;"
                v-show="navPath.indexOf('/wisdomLib') === -1 && navPath !== '/wisdom-upload-admin' && navPath !== '/' && navPath !=='/noPermission' && navPath.indexOf('/monitor') === -1 && navPath.indexOf('/eventEvolution/newsDetail') === -1 && navPath.indexOf('/warningMessage/newsDetail') === -1 && navPath !=='/perceptionReport' && navPath.indexOf('/perceptionReport/diyReport') === -1 && navPath !=='/apply'"/>
      <section class="content">
        <router-view/>
      </section>
    </section>
    <bottom-con v-if="domain === 'monitor.tiansongdata.com' || domain === 'yq.t1st.cc'"></bottom-con>
    <bottom-wrap v-else></bottom-wrap>
    <div class="backTop" @click="backTop()">
      <i class="el-icon-arrow-up" style="font-weight: bold;"></i>
    </div>
    <div class="backBottom" @click="toBottom()">
      <i class="el-icon-arrow-down" style="font-weight: bold;"></i>
    </div>
  </div>
</template>

<script>
import { getIsTrial, getToken } from '@/utils/auth'
import EventBus from '@/api/eventbus.js'
import HeaderWrap from '@/layout/components/HeaderWrap'
import NavWrap from '@/layout/components/NavWrap'
import bottomWrap from '@/layout/components/bottomWrap'
import bottomCon from '@/layout/components/tsbottom'
import SideBar from '@/components/SideBar'
import { getNav } from '@/api/layout'
import { push } from '@/api/notification'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { verificationDelay, getRemainingDaysToExpiration, logout } from '@/api/user'
import { removeToken } from '../utils/auth'
import router from '@/router'

var topCount = 1
var bottomCount = 1
export default {
  components: {
    HeaderWrap,
    NavWrap,
    bottomWrap,
    bottomCon,
    SideBar
  },
  data () {
    return {
      navPath: '/',
      num: 0,
      isConnect: false,
      custom: {},
      customType: {},
      dataList: [],
      domain: 'localhost',
      ctAccount: ''
    }
  },
  methods: {
    handle () {
      this.$refs.header.showNotice = false
      this.$refs.header.showExport = false
    },
    backTop () {
      topCount = 1
      this.toTopStep(50)
    },
    toTopStep (h) {
      document.documentElement.scrollTop -= h
      if (document.documentElement.scrollTop > 0) {
        if (document.documentElement.scrollHeight > 3000) {
          if (topCount <= 20) {
            var c = setTimeout(() => this.toTopStep(200), 16)
          } else {
            clearTimeout(c)
            document.documentElement.scrollTop = 0
          }
          topCount++
        } else {
          var c = setTimeout(() => this.toTopStep(50), 16)
        }
      } else {
        clearTimeout(c)
      }
    },
    toBottom () {
      bottomCount = 1
      this.toBottomStep(50)
    },
    toBottomStep (h) {
      var clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.documentElement.scrollHeight
      var height = scrollHeight - clientHeight
      document.documentElement.scrollTop += h
      if (document.documentElement.scrollTop < height) {
        if (document.documentElement.scrollHeight > 3000) {
          if (bottomCount <= 20) {
            var c = setTimeout(() => this.toBottomStep(200), 16)
          } else {
            clearTimeout(c)
            document.documentElement.scrollTop = height
          }
          bottomCount++
        } else {
          var c = setTimeout(() => this.toBottomStep(50), 16)
        }
      } else {
        clearTimeout(c)
      }
    },
    change (evt, hidden) {
      //hidden为false的时候，表示从别的页面切换回当前页面
      //hidden为true的时候，表示从当前页面切换到别的页面
      if (hidden === false) {
        var username = window.localStorage.getItem('login')
        if (this.$store.state.user.name !== username || !getToken()) {
          this.num = 1
          if (this.num === 1) {
            this.$alert('该账号已退出登录或登录状态已过期，如需继续操作该账号，请重新登录', {
              confirmButtonText: '确定',
              callback: action => {
                this.num = 0
                this.$router.push('/login')
                removeToken()
                this.$store.dispatch('app/setUrl', '')
              }
            })
          }

        }/* else{
            if(this.$route.path === '/' || this.$route.path.indexOf('/monitor/list') !== -1){
              this.$router.go(0)
            }
          } */
      }
    },
    /*initWebSocket() {
      this.connection();
      let self = this;
      // 断开重连机制,尝试发送消息,捕获异常发生时重连
       this.timer = setInterval(() => {
        try {
          self.stompClient.send("test");
        } catch (err) {
          console.log("断线了: " + err);
          self.connection();
        }
      }, 5000);
    },*/
    connection () {
      var _this = this
      // 建立连接对象
      //连接服务端提供的通信接口，连接以后才可以订阅广播消息和个人消息
      this.socket = new SockJS('https://' + document.domain + '/message/websocket')
      // 获取STOMP子协议的客户端对象
      this.stompClient = Stomp.over(this.socket, null, { timeout: 15000 })
      // 定义客户端的认证信息,按需求配置
      /* if(this.$store.state.user.token){
        this.custom.token = this.$store.state.user.token
      } */
      var num = 0
      const node = this.$createElement
      // 向服务器发起websocket连接
      this.stompClient.connect({}, (frame) => {
        if (this.$route.path.indexOf('/monitor/detail') !== -1 || this.$route.path.indexOf('/dataTrack/dataDetails') !== -1
          || this.$route.path.indexOf('/eventEvolution/newsDetail') !== -1) {
          this.disconnect()
          return
        }
        this.isConnect = true

        this.custom = JSON.parse(sessionStorage.getItem('curr_custom'))
        var subscribeURL = '/topic/' + this.custom.customerid + '/yujing'
        this.stompClient.subscribe(subscribeURL, (msg) => { // 订阅服务端提供的某个topic
          var message = JSON.parse(msg.body)
          push(message, node)
        })
        var autoImportSubscribeURL = '/topic/' + this.custom.customerid + '/auto_import'
        this.stompClient.subscribe(autoImportSubscribeURL, (msg) => {
          if (msg.body.indexOf('标题：') === 0) {
            this.$notify({
              title: '导入失败',
              message: msg.body,
              duration: 0,
              type: 'error'
            })
          } else {
            this.$notify({
              title: '任务完成',
              message: msg.body,
              duration: 0,
              type: 'success',
              onClick () {
                this.close()
                EventBus.$emit('taskCenterDialog', 'open')
              }
            })
          }
        })
        var createReportSubscribeURL = '/topic/' + this.custom.customerid + '/create_report'
        this.stompClient.subscribe(createReportSubscribeURL, (msg) => {
          if (msg.body.indexOf('error:') === 0) {
            this.$notify({
              title: '任务完成',
              message: msg.body.replace('error:', ''),
              duration: 0,
              type: 'error'
            })
          }
          if (msg.body.indexOf('warning:') === 0) {
            this.$notify({
              title: '任务完成',
              message: msg.body.replace('warning:', ''),
              duration: 0,
              type: 'warning'
            })
          }
          if (msg.body.indexOf('success:') === 0) {
            this.$notify({
              title: '任务完成',
              message: msg.body.replace('success:', ''),
              duration: 0,
              type: 'success'
            })
          }
        })
      }, (err) => {
        // 连接发生错误时的处理函数
        console.log(err)

        num++
        var time = 5000 + num * 3000
        this.reConnect(time)
      })

    },
    reConnect (time) {
      if (this.isConnect) return
      this.rec && clearTimeout(this.rec)
      this.rec = setTimeout(() => {
        console.log('重新连接')
        this.connection()
      }, time)
    },
    // 断开连接
    disconnect () {
      if (this.stompClient != null) {
        this.stompClient.disconnect()
        console.log('Disconnected')
      }
    },
    async getRemainingDaysToExpiration () {
      const res = await getRemainingDaysToExpiration()
      if (res.code === 0 && (res.data || res.data === 0)) {
        var oldCustomType = JSON.parse(localStorage.getItem('customType'))
        oldCustomType.discountTime = res.data
        this.customType = oldCustomType
        localStorage.setItem('customType', JSON.stringify(oldCustomType))
        if (res.data < 0) {
          localStorage.removeItem('customType')
          window.localStorage.setItem('logout', this.$store.state.user.name)
          sessionStorage.removeItem('curr_custom')
          removeToken()
          this.$store.dispatch('app/setUrl', '')
          this.$router.push('/login')
          this.$notify.error({
            title: '提醒',
            message: '您的账号已过期，请联系相关人员',
            duration: 0
          })
        }
      }
    }
  },
  async mounted () {
    this.ctAccount = localStorage.getItem('ct_account')
    this.domain = document.domain
    this.navPath = this.$route.path
    this.$store.dispatch('app/initNavList')
    this.$store.dispatch('user/UserInfo')
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    if (localStorage.getItem('yq_token') === '' ||
      localStorage.getItem('yq_token') === undefined ||
      localStorage.getItem('yq_token') === null) {
      this.$router.push('/login')
    }
    var refreshToken = setInterval((r) => {
      verificationDelay()
    }, 1000 * 60 * 10)
    this.connection()
    this.customType = JSON.parse(localStorage.getItem('customType'))
    this.getRemainingDaysToExpiration()
    if (window.location.hostname !== 'monitor.tiansongdata.com' && await getIsTrial() && localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide') === 'done') {
      setTimeout(() => {
        var currentGuideNumV2 = 1
        if (localStorage.getItem('currentGuideNumV2')) {
          currentGuideNumV2 = localStorage.getItem('currentGuideNumV2')
        } else {
          localStorage.setItem('currentGuideNumV2', currentGuideNumV2)
        }
        switch (parseInt(currentGuideNumV2)) {
          case 1:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('添加监测主题', '点击添加按钮可以快速添加监测主题，每个账号有5次添加的机会', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
            })
            break
          case 2:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('基础筛选器', '可通过发布时间、情感筛选数据，并且可以合并相似新闻，调整排序方式', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
            })
            break
          case 3:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('来源筛选', '可通过数据来源精准筛选数据，且可查看各个来源对应数据总量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
            })
            break
          case 4:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('视频网站筛选', '可以更加精准的对视频数据按照所属网站或客户端进行筛选，且可查看各个视频网站对应的数据总量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
            })
            break
          case 5:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('文本检索', '支持通过各种关键词组合模糊检索数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '')
            })
            break
          case 6:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('数据导出', '可以将当前查询条件的所有数据进行导出', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
            })
            break
          case 7:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('报告快速导出', '可以通过提前制作好的报告模板，将当前查询条件范围内的数据进行报告生成', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
            })
            break
          case 8:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 500 })
              this.$showGuide('数据导入', '支持手动对数据进行导入补充，以应对小众站点未被爬虫收录抓取对情况', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
            })
            break
          case 9:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('项目切换', '点击对您对账号所管理对项目进行快速切换', 'guideElement' + currentGuideNumV2, 'after', true, '', '')
            })
            break
          case 10:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('下载中心', '点击进入下载中心查看导出任务', 'guideElement' + currentGuideNumV2, 'after', true, '', '')
            })
            break
          case 11:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('消息中心', '点击进入消息中心查看系统消息推送', 'guideElement' + currentGuideNumV2, 'after', true, '', '')
            })
            break
          case 12:
            this.$router.push('/monitor/list').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('跳转客户端', '点击直接免登录跳转打开客户端', 'guideElement' + currentGuideNumV2, 'after', false, '', '')
            })
            break
          case 13:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('舆情概况', '查看当前项目的整体概览情况，快速知晓当前网络声量', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 14:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('最新舆情', '展示当前项目最新的舆情数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 15:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('负面舆情', '展示当前项目最新的负面舆情数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 16:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 300 })
              this.$showGuide('情感走势', '展示当前项目所有互联网声量的情感走势', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 17:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 500 })
              this.$showGuide('舆情分布', '展示当前项目所有互联网声量的情感分布占比', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 18:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 500 })
              this.$showGuide('站点排行', '展示当前项目所有互联网声量的站点排行TOP10', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 19:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 800 })
              this.$showGuide('热词云图', '展示当前项目数据分词后统计的热词', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 20:
            this.$router.push('/').then(() => {
              window.scrollTo({ top: 800 })
              this.$showGuide('标签云图', '展示当前项目命中标签的情况', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 21:
            this.$router.push('/statistics/dimension').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('数据统计', '查看当前项目整体的各个纬度的统计信息', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 22:
            this.$router.push('/statistics/dimension').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('统计筛选', '根据时间进行筛选', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 23:
            this.$router.push('/intelligence').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('情报中心', '查看当前项目的人工标记或系统自动标记的重点情报数据', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 24:
            this.$router.push('/perceptionReport').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('收藏夹', '对添加到收藏夹中，需要二次处理的数据进行处理', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 25:
            this.$router.push('/perceptionReport/templates').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('舆情报告', '制作报告模板，手动生成报告，或者设置生成报告的定时任务', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 26:
            this.$router.push('/perceptionReport/diyReport/import/531051109423386624').then(() => {
              document.getElementById('guideElement26-box').scrollIntoView({ block: 'start' })
              this.$showGuide('报告设计器', '拖动左侧组件库中的组件到报告模板设计器中制作报告模板', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 27:
            this.$router.push('/eventEvolution').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('事件演化', '针对重点关注事件创建事件演化，持续关注事件走向', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
          case 28:
            this.$router.push('/warningMessage').then(() => {
              window.scrollTo({ top: 0 })
              this.$showGuide('预警消息', '查看当前项目生成的预警消息', 'guideElement' + currentGuideNumV2, 'after', false, '#FFF', '5px')
            })
            break
        }
      }, 1000)
    }
  },
  beforeDestroy: function () {
    // 页面离开时断开连接,清除定时器
    this.disconnect()
    // clearInterval(this.timer);
  },
  computed: {
    // 监听数据
    getnewValue () {
      // return this.$store.state.app.newValuePath
      return this.$route.path
    }
  },
  watch: {
    //  监听改变事件
    getnewValue: {
      handler (newValue, oldValue) {
        this.navPath = newValue
        try {
          if (newValue !== '/' && newValue !== '/noPermission') {
            /* if (newValue.indexOf("/monitor/list") !== -1 || this.$route.path.indexOf("/monitor/add") !== -1) {
              // if (newValue.substr(1).split("/")[2] !== oldValue.substr(1).split("/")[2]) {
                this.$refs.detectbar.getList()
              // }
            } else */
            if (newValue.indexOf('/perceptionReport') !== -1) {
              this.$refs.sidebar.getList('/perceptionReport/dailyReport')
            } else if (newValue.substr(1).split('/')[0] === 'settings') {
              if (newValue.substr(1).split('/')[1] === 'theme' || newValue.substr(1).split('/')[1] === 'userManagement') {
                if (newValue.substr(1).split('/')[2]) {
                  this.$refs.sidebar.getList('/settings/project')
                } else {
                  this.$refs.sidebar.getList('/settings/project/custom')
                }
              } else if (newValue.substr(1).split('/')[1] === 'project') {
                if (newValue.substr(1).split('/')[2]) {
                  this.$refs.sidebar.getList('/settings/project/custom')
                } else {
                  this.$refs.sidebar.getList('/settings/project')
                }
              } else if (newValue.substr(1).split('/')[1] === 'role') {
                this.$refs.sidebar.getList('/settings/role')
              } else {
                this.$refs.sidebar.getList(newValue)
              }
            } else if (newValue.substr(1).split('/')[0] === 'statistics') {
              this.$refs.sidebar.getList('/statistics/dimension')
            } else if (newValue.substr(1).split('/')[0] === oldValue.substr(1).split('/')[0]) {
              this.$refs.sidebar.getList('/' + newValue.substr(1).split('/')[0])
            } else {
              this.$refs.sidebar.getList(newValue)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
html, body {
  height: 100%;
}

.app-main {
  width: 100%;
  min-height: 100vh;
  // padding: 30px 30px;
  padding-bottom: 30px;
  padding-right: 20px;
  // overflow-y: scroll;
  display: flex;

  .content {
    width: 100%;
    min-width: calc(100% - 320px);
    // min-height: $contentHeight;
    margin-left: 20px;
  }
}

.backTop {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  color: #999aaa;
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 99;
  cursor: pointer;
  font-size: 20px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  margin-bottom: 10px;
}

.backBottom {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  right: 10px;
  border-radius: 5px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999aaa;
  background-color: #fff;
  // border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  font-size: 20px;
}
</style>
<style>
.stylecss .el-notification__title {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/*.arrow-box-tip {*/
/*  position: absolute;*/
/*  background-color: rgba(52, 114, 199, 1);*/
/*  !*backdrop-filter: blur(15px);*!*/
/*  width: 320px;*/
/*  padding: 20px 10px 10px 10px;*/
/*  color: #FFF;*/
/*  border-radius: 15px;*/
/*  z-index: 8;*/
/*  border-color: transparent transparent #FFF transparent;*/
/*  overflow: visible; !* 设置为可见，以便箭头超出父元素 *!*/
/*}*/

/*.arrow-box-tip::after {*/
/*  content: "";*/
/*  width: 0;*/
/*  height: 0;*/
/*  border-style: solid;*/
/*  border-width: 10px;*/
/*  border-color: transparent transparent transparent rgba(52, 114, 199, 1);*/
/*  !*backdrop-filter: blur(15px);*!*/
/*  position: absolute;*/
/*  z-index: 1000;*/
/*  top: 53px; !* 调整箭头位置 *!*/
/*  left: -20px; !* 调整箭头位置 *!*/
/*  transform: rotate(180deg);*/
/*}*/
.qr-wrapper {
  position: relative;
  display: inline-block;
}

.qr-wrapper img {
  display: block; /* Ensures there is no extra space inside the wrapper */
  width: 200px; /* Adjust the size as needed */
  height: 200px; /* Adjust the size as needed */
}

.corner {
  position: absolute;
  width: 80px; /* Width of the corner pieces */
  height: 80px; /* Height of the corner pieces */
  border: 3px solid #E3EBF6; /* Adjust border color and size */
  border-radius: 30px; /* Adjust the border radius */
}

.top-left {
  top: -20px; left: -20px;
  border-right: none;
  border-bottom: none;
  clip-path: polygon(0% 0%, 70% 0%, 0% 70%);
}

.top-right {
  top: -20px; right: -20px;
  border-left: none;
  border-bottom: none;
  clip-path: polygon(100% 0%, 100% 70%, 30% 0%);
}

.bottom-left {
  bottom: -20px; left: -20px;
  border-top: none;
  border-right: none;
  clip-path: polygon(0% 100%, 0% 30%, 70% 100%);
}

.bottom-right {
  bottom: -20px; right: -20px;
  border-top: none;
  border-left: none;
  clip-path: polygon(100% 100%, 30% 100%, 100% 30%)
}

</style>

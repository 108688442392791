import request from '@/utils/request'

// 查询消息列表
export function getTaskByCid (data) {
  return request({
    url: '/downloadTask/getTaskByCid',
    method: 'POST',
    data
  })
}
// 删除
export function delectTaskFile (data) {
  return request({
    url: '/downloadTask/delectTaskFile',
    method: 'POST',
    data
  })
}
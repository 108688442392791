import request from '@/utils/request'

// 查询消息列表
export function listNotification (data) {
  return request({
    url: '/sys/notification/listNotification',
    method: 'POST',
    data
  })
}

// 设为已读
export function setRead (data) {
  return request({
    url: '/sys/notification/setRead',
    method: 'POST',
    data
  })
}

// 发送通知
export function sendNotification (data) {
  return request({
    url: '/sys/notification/sendNotification',
    method: 'POST',
    data
  })
}
// 删除
export function deleteNotification (data) {
  return request({
    url: '/sys/notification/delete',
    method: 'POST',
    data
  })
}
// 查询发送通知
export function listSendRecord (data) {
  return request({
    url: '/sys/notification/listSendRecord',
    method: 'POST',
    data
  })
}
// 未读数量
export function getCount (data) {
  return request({
    url: '/sys/notification/unreadCount',
    method: 'POST',
    data
  })
}

<template>
  <section class="wrapper">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>消息通知</span>
        <i style="float: right;color: #999;font-size: 18px;" class="el-icon-close" @click.stop="noticeClose"></i>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane label="已读" name="1"></el-tab-pane>
        <el-tab-pane label="未读" name="0"></el-tab-pane>
      </el-tabs>
      <div id="resultScroll"  ref="myScrollbar" @scroll="handleScroll()">
        <ul>
          <li v-for="(item,index) in data">
            <el-badge :is-dot="item.isRead==0?true:false" class="item">
              <div class="title" style="cursor: pointer;" @click="setRead(item)">
                <span>{{item.title}}</span>
                <i class="el-icon-delete" @click.stop="deleteNotice(item.id,index)"></i>
              </div>
            </el-badge>
            <div v-show="item.msgShow" class="content">{{item.msg}}</div>
          </li>
        </ul>
        <div class="tips" v-if="data.length % 10 >= 0">没有更多了</div>
        <div class="tips" v-else>加载中...</div>
      </div>
    </el-card>
  </section>
</template>

<script>
  import { listNotification,setRead,deleteNotification } from '@/api/notice'
  export default {
    props:{
      noticeClose: {
        type: Function,
        default: null,
      }
    },
    data() {
      return {
        isRead:-1,
        count: 0,
        currentPage: 1,
        size: 10,
        data:[],
        totalPage:0,
        activeName: '-1'
      };
    },
    mounted() {
      this.getList()
      // document.getElementById('resultScroll').addEventListener('scroll', this.handleScroll,true)
    },
    methods:{
      handleScroll(){
        var that = this
        var sh = that.$refs['myScrollbar'].scrollHeight // 滚动条高度
        var st = that.$refs['myScrollbar'].scrollTop // 滚动条距离顶部的距离
        var ch = that.$refs['myScrollbar'].clientHeight // 滚动条外容器的高度
        if (st + ch >= sh) {
            //到底了-业务逻辑
            that.currentPage += 1
            if(that.currentPage<=that.totalPage){
                that.getList()
            }
        }
      },
      handleClick(tab, event) {
        this.isRead = tab.name
        this.currentPage = 1
        this.data = []
        this.getList()
      },
      async getList(){
        const res = await listNotification({ currentPage: this.currentPage, pageSize: this.size, isRead:this.isRead })
        this.totalPage =  Math.ceil(res.count/10)
        console.log(this.totalPage)
        res.data.forEach(item=>{
          item.msgShow = false
        })
        this.data.push(...res.data)
      },
      async setRead(item){
        if(item.msgShow){
          item.msgShow = false
        }else{
          item.msgShow = true
          item.isRead = 1
          const res = await setRead({ id: item.id })
          this.$emit('setRead')
        }
      },
      deleteNotice(id,index){
        this.$confirm('是否要删除该通知内容?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await deleteNotification([id])
          if(res.code == 0){
            this.$message({type: 'success',message: '删除成功!'});
            this.data.splice(index,1)
          }else{
            this.$message({type: 'error',message: res.msg});
          }
        }).catch(() => {
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper{
    width: 40%;
    position: absolute;
    z-index: 999;
    // bottom: 10px;
    right: 50px;
    line-height: normal;
    .box-card{
      height: 400px;
      ul,li{
        margin: 0;
        padding: 0;

      }
      /deep/ .el-card__header{
        padding: 10px 15px;
      }
      /deep/ .el-card__body{
        height: 90%;
        padding: 0;
        .el-tabs__header{
          margin: 0 10px;
          padding: 0;
        }
        #resultScroll{
          overflow-y: scroll;
          height: calc(100% - 40px);
          padding: 10px;

          &::-webkit-scrollbar {
           width: 8px;
          }
          &::-webkit-scrollbar-track {
           background-color:#f8f8f8;
           -webkit-border-radius: 2em;
           -moz-border-radius: 2em;
           border-radius:2em;
          }
          &::-webkit-scrollbar-thumb {
           background-color:#dddddd;
           -webkit-border-radius: 2em;
           -moz-border-radius: 2em;
           border-radius:2em;
          }
        }
      }
      li{
        border-top: 1px solid #e5e5e5;
        min-height: 50px;
        line-height: 1.8;
        padding-left: 10px;
        .title{
          line-height: 50px;
        }
        .el-icon-delete{
          float: right;
          color: #9ca6b1;
          margin-top: 15px;
        }

      }
      li:last-child{
        border-bottom: 1px solid #e5e5e5;
      }
      .tips{
        text-align: center;
        margin-top: 15px;
        color: #c8c8c8;
        font-size: 13px;
        /* position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -30px; */
      }
      .el-badge{
        width: 100%;
      }
      /deep/ .el-badge__content.is-fixed{
        top: 25px;
        left: -20px;
      }
      .content{
        font-size: 14px;
        color: #9da7b1;
      }
    }
  }
</style>

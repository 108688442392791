<template>
  <section class="wrapper">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>任务列表</span>
        <i style="float: right;color: #999;font-size: 18px;" class="el-icon-close" @click.stop="exportClose"></i>
      </div>
      <el-table :data="tableData" border v-loading="loading" ref="table" height="330px" style="width: 100%">
        <el-table-column  :resizable="false" prop="alias" align="center" label="文件名称"></el-table-column>
        <el-table-column  :resizable="false" prop="state" align="center" width="160px" label="状态">
          <template scope="{ row }">
            {{ row.state === 0? '处理中' : '已完成' }}
          </template>
        </el-table-column>
        <el-table-column  :resizable="false" label="操作" align="center" width="100px">
          <template scope="{ row }">
              <el-button v-if="row.ownedFunction !== '批量修改媒体站点'" type="text" @click.stop="handleFun (row.id)">下载</el-button>
              <el-button type="text" @click.stop="deleteFun (row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next"
                       @size-change="sizeChange" @current-change="currentChange" :total="count">
        </el-pagination>
      </section>
    </el-card>
  </section>
</template>

<script>
  import { getTaskByCid,delectTaskFile } from '@/api/export'
  import { getToken } from '@/utils/auth'
  import { apiBaseURL } from '@/utils/request'
  export default {
    props:{
      exportClose:{
        type: Function,
        default: null,
      }
    },
    data() {
      return {
        activeName: '1',
        isRead:-1,
        count: 0,
        currentPage: 1,
        size: 10,
        loading:false,
        tableData:[]
      };
    },
    mounted(){
      this.getList()
    },
    methods:{
      async getList(){
        const res = await getTaskByCid({ current: this.currentPage, pageSize: this.size })
        this.tableData = res.data
        this.count = res.count
      },
      handleFun(id){
        let token = getToken()
        let down = document.createElement('a')
        down.href = apiBaseURL + '/downloadTask/downloadFile?&token=' + token + '&id='+id
        down.click()
      },
      async deleteFun(id){
        var res = await delectTaskFile({id:id})
        if(res.code == 0){
          this.$message.success('删除成功')
          this.getList()
        }
      },
      sizeChange: async function (i) {
        // this.loading = true
        this.currentPage = 1
        this.size = i
        this.getList()
      },
      currentChange: async function (i) {
        // this.loading = true
        this.currentPage = i
        this.getList()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper{
    width: 40%;
    position: absolute;
    z-index: 999;
    // bottom: 10px;
    right: 50px;
    line-height: normal;
    .box-card{
      height: 450px;
    }
    /deep/ .el-card__header{
      padding: 10px 15px;
    }
    /deep/ .el-card__body{
      padding: 10px;
    }
  }
</style>
